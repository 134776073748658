<template>
    <div class="footer">
        <p class="made">Сделано в:</p>
        <a href="https://xn--g1ani7c.xn--p1ai/" target="_blank" rel="noopener noreferrer" class="closed-when-mobile">
            <img class="sous__img" src="@/assets/img/souz.svg" alt="logo" />
        </a>
        <a href="https://xn--g1ani7c.xn--p1ai/" target="_blank" rel="noopener noreferrer" class="close_wy_big_desktop">
            <img class="sous__img" src="@/assets/img/souz_mobile.svg" alt="logo" />
        </a>
        <img class="line__img" src="@/assets/img/Line 1.svg" alt="l" />
        <p class="icrew">iCrew</p>
    </div>

</template>
  
  <script>

  export default {
    name: 'Footer',
    data() {
      return {};
    },
    computed: {},
    mounted() {},
    methods: {}
  };
  </script>
  <style scoped>
  .footer {
    display: flex;
    flex-direction: row;
    gap: 7px;
    justify-content: end;
    padding-right: 29px;
  }
  
  .made {
    width: 94px;
    height: 17px;
    font-family: 'Inter', sans-serif; 
    font-weight: 500;
    font-size: 16px;
    line-height: 19.36px;
    color: white;
    padding-right: 5px;
    padding-top: 5px;
  }

  .icrew {
    width: 51px;
    height: 22px;
    font-family: 'Inter', sans-serif; 
    font-weight: 600;
    font-size: 18px;
    line-height: 21.78px;
    color: white;
    padding-top: 4px;
  }

  .sous__img {
    /* padding-bottom: 5px; */
  }

  @media (max-width: 1024px) {
    .footer {
        justify-content: center;
        padding-right: 0px;
    }

    .made {
        width: 69px;
        height: 13px;
        font-size: 12px;
        line-height: 14.52px;
        padding-right: 5px;
        padding-top: 0px;
    }

    .icrew {
        width: 34px;
        height: 15px;
        font-size: 12px;
        line-height: 14.52px;
        padding-top: 0px;
    }
    .sous__img {
        width: 73px;
        height: 11px;
        margin-top: 1px;
    }

    .line__img {
        height: 11px;
        margin-top: 1px;
    }
}
  </style>