<template>
 
  <div class="block_notification">
    <div id="vk_send_message" class="notification"></div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import apiClient from "../axios/axios.js";

export default {
  name: 'VKAllowMessages',
  data() {
    return {
      form: {
        token: '' // Инициализация токена
      }
    };
  },
  computed: {
    ...mapGetters(['getToken']),
  },
  mounted() {
    this.form.token = this.getToken; // Установите токен из Vuex
    // Загрузка библиотеки VK API
    const script = document.createElement('script');
    script.src = 'https://vk.com/js/api/openapi.js?168';
    script.onload = this.initVKWidget;
    document.head.appendChild(script);
  },
  methods: {
    initVKWidget() {
      VK.Widgets.AllowMessagesFromCommunity("vk_send_message", { height: 30 }, 226422275);
      VK.Observer.subscribe('widgets.allowMessagesFromCommunity.allowed', (userId) => {
        console.log(userId);
        console.log('allowed');
        this.$emit('user-allowed', userId);
        this.sendUserData(userId); // Отправляем данные на сервер
      });
      VK.Observer.subscribe('widgets.allowMessagesFromCommunity.denied', (userId) => {
        console.log(userId);
        console.log('denied');
        this.$emit('user-denied');
        alert("Вы запретили уведомления в ВКонтакте!");
        this.deleteUserData(userId); // Удаляем данные пользователя
      });
    },
    async sendUserData(userId) {
      try {
        const response = await apiClient.patch(`http://51.21.19.52:8000/api/store-vk-user-id`, {
          token: this.form.token,
          vk_user_id: userId
        });
        console.log('Данные успешно отправлены:', response.data);
      } catch (error) {
        console.error('Ошибка при отправке данных:', error.response ? error.response.data : error.message);
      }
    },
    async deleteUserData(userId) {
      try {
        const response = await apiClient.patch(`http://51.21.19.52:8000/api/delete-vk-user-id`, {
          token: this.form.token,
          vk_user_id: userId
        });
        console.log('Данные успешно удалены:', response.data);
      } catch (error) {
        console.error('Ошибка при удалении данных:', error.response ? error.response.data : error.message);
      }
    }
  }
};
</script>
