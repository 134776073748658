import axios from 'axios';
import store from '../store/index.js';

const apiClient = axios.create({
  baseURL: 'http://51.21.19.52:8000/api',
  headers: { 'Content-Type': 'application/json'
   }
});

apiClient.interceptors.request.use(config => {
  const Token = store.getters.getToken;
  if (Token) {
    config.headers.Authorization = `Bearer ${Token}`;
    // console.log('Request headers:', config.headers); 
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default apiClient;