<template>
  <div class="newheader">
    <!-- УВЕДОМЛЕНИЯ -->
    <div  v-show="toggleNotifications"  class="window_notification">
      <img @click="toggleNotifications = false" src="@/assets/img/left_close.svg" class="close__icon-popup_notification" />
      <VKAllowMessages />
    </div>

    <div class="newheader__left" v-show="mobile_calendar">
      <MBlockLeftLogoTitle />

      <div v-show="mobile_calendar" class="close-img">
        <img @click="mobile_calendar = !mobile_calendar" src="@/assets/img/m-tasks/close.svg" class="close__icon" />
      </div>

      <div class="newheader__left__bottom newheader__left__bottom__padding">
        <div class="newheader__left__bottom__calendar newheader__left__bottom__calendar__padding newheader__container">
          <div class="newheader__left__bottom__calendar_top newheader__left__bottom__calendar_top__padding">
            <div class="newheader__left__month">
              <span> {{ currentMonthName }} {{ currentYear }}</span>
            </div>
            <div class="newheader__left__flex">
              <img @click="previous_month" class="newheader__left__arrow" src="@/assets/img/left_arrow.svg"
                alt="left_arrow" />
              <img @click="next_month" class="newheader__left__arrow" src="@/assets/img/right_arrow.svg"
                alt="right_arrow" />
            </div>
          </div>
          <div class="newheader__left__calendar closed-when-mobile">
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">В</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">Ч</div>
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">В</div>
            <div v-for="(item, index) in calendarDays" :key="index"
              :class="{ 'today': item === day && currentMonth == month && currentYear === year }"
              @click="updateWeek(item)">
              <span v-if="item">{{ item }}</span>
            </div>
          </div>
          <div class="newheader__left__calendar close_grid_desktop">
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">В</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">Ч</div>
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">В</div>
            <div v-for="(item, index) in calendarDays" :key="index"
              :class="{ 'today': item === day && currentMonth == month && currentYear === year }"
              @click="handleDateClick(item)">
              <span v-if="item">{{ item }}</span>
            </div>
          </div>
        </div>

        <div class="newheader__left__bottom__track newheader__left__bottom__track__padding">
          <router-link :to="{ name: 'MAssignTaskUser' }" class="custom-link">
            <div class="newheader__left__bottom__track__block">
              <span>Добавить задачу</span>
              <img src="@/assets/img/left_plus.svg" alt="left_plus" />
            </div>
          </router-link>
          <router-link :to="{ name: 'MCompletedUser' }" class="custom-link">
            <div class="newheader__left__bottom__track__block">
              <span>Выполненные</span>
              <img src="@/assets/img/left_check.svg" alt="left_check" />
            </div>
          </router-link>
          <router-link :to="{ name: 'MUnfulfieldUser' }" class="custom-link">
            <div class="newheader__left__bottom__track__block">
              <span>Невыполненные</span>
              <img src="@/assets/img/left_close.svg" alt="left_close" />
            </div>
          </router-link>
        </div>
        <FooterLeft />
      </div>

    </div>
    <!-- правая часть -->
    <div class="newheader_right">
      <!-- Новый блок меню от Дениса НАЧАЛО -->
      <header class="mobile__header">
        <section class="head">
          <img @click="mobile_calendar = !mobile_calendar" src="../assets/img/m-tasks/burger_menu.svg"
            class="burger_menu" />
          <div class="head__logo-container">
            <img @click="stop" src="../assets/img/m-tasks/mobile-cloud.svg" class="head__logo" />
            <p class="head__logo-text">Облако задач</p>
          </div>
          <img @click="logout" src="../assets/img/m-tasks/mobile-logout.svg" class="mob_logout" />
        </section>

        <section class="head-panel__container">
          <div class="head-panel__up-content">
            <div class="head-panel__text">
              <h4 class="head-panel__up-title">Задачи</h4>
              <!-- <h4 class="head-panel__up-title">Задачи на {{selectedDateWeek}}</h4> -->
              <p class="head-panel__date">{{selectedDateWeek}} </p>
            </div>

            <img @click="toggleNotifications= !toggleNotifications" src="@/assets/img/m-tasks/bell.svg" class="head__img bell__img" />
          </div>
          <div class="head-panel__down-today">
            <!-- <h4 class="head-panel__down-content">Сегодня</h4> -->
            <h4 class="head-panel__today">Сегодня {{dayToday}}</h4>
            <div class="head__img-container">
              <img class="head__img" src="../assets/img/m-tasks/white-search.svg" alt="search_right"
                v-show="!search_open" @click="search_open = !search_open" />
              <div class="head__search__open" v-show="search_open">
                <input class="head__search__open__input" v-model="query" @input="searchInNonConfirm" type="text"
                  placeholder="Начните вводить" />
                <img class="head__img" src="@/assets/img/search_right.svg" alt="search_right"
                  @click="search_open = false" />
              </div>
              <!-- <img src="@/assets/img/header__filter640.svg" alt="header__filter" class="head__img filter-img"
                @click="menu_popup = !menu_popup" /> -->
            </div>
          </div>
        </section>
      </header>
      <!-- Новый блок меню от Дениса КОНЕЦ -->

      <!-- хедер десктоп -->
      <div class="newheader__right closed-when-mobile">
        <div class="newheader__right__empl1 ">
          <div class="newheader__right__problems">
            <h2 class="newheader__right__top__title">Задачи</h2>
            <div class="newheader__right__bottom__right newheader__right__bottom__right__padding1">
              <div class="newheader__search">
                <input v-model="query" @input="searchInNonConfirm" type="text"
                  placeholder="Введите что-то для поиска" />
                <img class="newheader__right__search" src="@/assets/img/search_right.svg" alt="search_right" />
              </div>
              <div class="newheader__filter newheader__filter__padding" @click="menu_popup = !menu_popup" >
                <img src="@/assets/img/header__filter.svg" alt="header__filter" />
              </div>
            </div>
            <div class="newheader__accaunt">
              <div  @click="toggleNotifications= !toggleNotifications" class="newheader__right__bell">
                <div class="newheader__right__top__wing newheader__right__top__wing__padding1">
                  <img src="@/assets/img/wing.svg" />
                </div> 
              </div>
              <div class="newheader__right__top__exit newheader__right__top__exit__padding2">
                <img @click="logout" src="@/assets/img/exit.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ФИЛЬТР МЕНЮ -->
      <!-- <section class="menu" v-show="menu_popup" @click="menu_popup = !menu_popup">
        <div class="menu__popup">
          <p class="menu__title">Сортировать</p>
          <button class="menu__btn" @click="1">От А до Я</button>
          <button class="menu__btn" @click="1">По дате</button>
        </div>
      </section> -->

      <!-- таблица -->
      <div class="mobile-table closed-when-mobile">
        <table class="week">
          <thead>
            <tr class="table-row_head">
              <th class="day"></th>
              <th class="day"  :class="{'today-header': currentDayIndex === 1 && todayDays === weekDates[0]}">
                <span class="day_week">ПН</span>
                <span class="day_month closed-when-mobile">{{ weekDates[0] }}</span>
              </th>
              <th  :class="{'today-header': currentDayIndex === 2 && todayDays === weekDates[1]}">
                <span class="day_week">ВТ</span>
                <span class="day_month closed-when-mobile">{{ weekDates[1] }}</span>
              </th>
              <th :class="{'today-header': currentDayIndex === 3 && todayDays === weekDates[2]}">
                <span class="day_week">СР</span>
                <span class="day_month closed-when-mobile">{{ weekDates[2] }}</span>
              </th>
              <th :class="{'today-header': currentDayIndex === 4 && todayDays === weekDates[3]}">
                <span class="day_week">ЧТ</span>
                <span class="day_month closed-when-mobile">{{ weekDates[3] }}</span>
              </th>
              <th :class="{'today-header': currentDayIndex === 5 && todayDays === weekDates[4]}">
                <span class="day_week">ПТ</span>
                <span class="day_month closed-when-mobile">{{ weekDates[4] }}</span>
              </th>
              <th :class="{'today-header': currentDayIndex === 6 && todayDays === weekDates[5]}">
                <span class="day_week">СБ</span>
                <span class="day_month closed-when-mobile">{{ weekDates[5] }}</span>
              </th>
              <th :class="{'today-header': currentDayIndex === 0 && todayDays === weekDates[6]}">
                <span class="day_week">ВС</span>
                <span class="day_month closed-when-mobile">{{ weekDates[6] }}</span>
              </th>
            </tr>
          </thead>
          <tbody class="bodiTable closed-when-mobile">
            <tr v-for="interval in TimeIntervals" :key="interval.start" class="table-row" :style="{height: intervalTaskCounts[interval.start] * 60 + 'px'}">
              <td class="first-column">
                <p>{{ interval.start }}</p>
                <p>-</p>
                <p>{{ interval.end }}</p>
              </td>
              <template v-for="(day, index) in days" :key="index">
                <!-- Ячейка для каждого дня и интервала -->
                <td class="task-cell">
                  <template v-if="preparedTasks[index] && preparedTasks[index][interval.start] && preparedTasks[index][interval.start].length > 0">
                    <!-- Если есть задачи, отображаем их -->
                    <div v-for="(task, taskIndex) in preparedTasks[index][interval.start]" :key="task.id" class="task-container">
                      <button 
                        @click="openTaskDetails(task)" 
                        class="task-block" 
                        :style="getTaskStyles(task,taskIndex, index, interval.start)">
                        {{ task.name.split(':::')[0] }}
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <!-- Если задач нет, ячейка остается пустой -->
                  </template>
                </td>
              </template>
            </tr>
          </tbody>
</table>
      </div>

<!-- вывод задач в планшете и мобильном -->

<div class="mobile-table close_wy_desktop">
        <table class="week">
          <thead>
            <tr class="table-row_head">
              <th class="day"></th>
              <th @click="updateSelectedDate(0)" class="day" :class="{ 'today-header': currentDayIndex === 1 && todayDays === weekDates[0] }">
                <span class="day_week">ПН</span>
                <span class="day_month">{{ weekDates[0] }}</span>
              </th>
              <th @click="updateSelectedDate(1)" :class="{ 'today-header': currentDayIndex === 2 && todayDays === weekDates[1] }">
                <span class="day_week">ВТ</span>
                <span class="day_month">{{ weekDates[1] }}</span>
              </th>
              <th @click="updateSelectedDate(2)" :class="{ 'today-header': currentDayIndex === 3 && todayDays === weekDates[2] }">
                <span class="day_week">СР</span>
                <span class="day_month">{{ weekDates[2] }}</span>
              </th>
              <th @click="updateSelectedDate(3)" :class="{ 'today-header': currentDayIndex === 4 && todayDays === weekDates[3] }">
                <span class="day_week">ЧТ</span>
                <span class="day_month">{{ weekDates[3] }}</span>
              </th>
              <th @click="updateSelectedDate(4)" :class="{ 'today-header': currentDayIndex === 5 && todayDays === weekDates[4] }">
                <span class="day_week">ПТ</span>
                <span class="day_month">{{ weekDates[4] }}</span>
              </th>
              <th @click="updateSelectedDate(5)" :class="{ 'today-header': currentDayIndex === 6 && todayDays === weekDates[5] }">
                <span class="day_week">СБ</span>
                <span class="day_month">{{ weekDates[5] }}</span>
              </th>
              <th @click="updateSelectedDate(6)" :class="{ 'today-header': currentDayIndex === 0 && todayDays === weekDates[6] }">
                <span class="day_week">ВС</span>
                <span class="day_month">{{ weekDates[6] }}</span>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <!-- карточки задач для планшета -->
      <div class="as_tasks__cardcontainers">
        <template v-if="todayTasks.length !== 0">
          <div v-for="task in todayTasks" :key="task.id" @click="openTaskDetails(task)"
            :class="task.is_urgently ? 'task-block as_tasks__is_urgently' : 'task-block as_tasks__is_not__urgently'"
          >
            <h4 class="task__assign__title task-block-mobile">{{ task.name.split(':::')[0] }}</h4>
            <p class="task__assign__day task-block-mobile">{{ formatDate(task.start_date.substring(0, 10)) }}</p>
          </div>
        </template>
        <template v-else>
          <div class="empty-container">
            <p class="as_tasks__empty">Нет задач на сегодня</p>
          </div>
        </template>
      </div>

      <button v-if="!open_task" class="task__button__popup " @click="open_task = true">
        <span>Назначить задачу</span>
        <img class="right__image" src="@/assets/img/right__plus.svg" alt="right__plus" />
      </button>
       
      <!-- попап создания задачи -->

      <section class="task__popup" v-show="open_task" @click="open_task = false">
        <div class="task__popup__content" @click.stop>
          <div class="task__popup__header">
            <input 
              type="text" 
              class="task__popup__header__status" 
              :class="{ 'input-error-in-create-task': formInputWithoutStatusValueError }"
              v-model="form.is_urgently" 
              :placeholder="!formInputWithoutStatusValueError ? 'Статус задачи' : 'Вы не выбрали статус'"
              @mouseenter="showDropdownMenu" 
              @blur="hideDropdown"
              readonly
            >
            <img @click="open_task = false" src="../assets/img/m-tasks-user/close-popup-icon.svg"
              class="close__icon-popup" />
            <div class="dropdown-content__block">
              <div class="dropdown-content" @mouseenter="clearHideDropdown" v-show="showDropdown"
                @mouseleave="hideDropdown">
                <div class="dropdown_status">
                  <span>Статус задачи</span>
                </div>
                <div class="dropdown-item" @mousedown="selectItem('Обычная задача')"><span>Обычная задача</span></div>
                <div class="dropdown-item" @mousedown="selectItem('Срочная задача')"><span>Срочная задача</span></div>
              </div>
            </div>
          </div>

          <div class="task__popup__scroll">
            <!-- ДЕТАЛИ ОПИСАНИЯ -->

            <!-- ПУНКТЫ -->
            <div class="task__popup__list-container_task">
              <div class="task__popup__block">
                
                <div class="task__popup__list__content_big">
                  <div class="task__popup__list__check">
                    <p class="task__popup__name_input">Название задачи</p>
                    <input 
                      class="task__popup__list task__popup__list__block" 
                      :class="{ 'input-error-in-create-task': formInputWithoutNameValueError }"
                      v-model="form.name" 
                      name="task_name"
                      :placeholder="!formInputWithoutNameValueError ? 'Введите название задачи' : 'Вы не ввели название'"
                    >
                  </div>
                </div>
                
                <p class="task__popup__name_input">Сроки выполнения задачи</p>
                <div class="two_calendar">
                  <div class="task__popup__list__content_time">
                    <div class="task__popup__list__content__item">
                      <input 
                        v-model="input_date" 
                        class="task__popup__list task__popup__list__block" 
                        :class="{ 'input-error-in-create-task': formInputWithoutDateValueError }"
                        type="text"
                        name="task_emp" 
                        :placeholder="!formInputWithoutDateValueError ? 'Выберите даты и время завершения задачи' : 'Вы не выбрали сроки'"
                        readonly
                      >
                      <!-- <img class="task__popup__list__image" src="@/assets/img/m-assign-task/icon_task_calendar.svg"
                        alt="icon_task_calendar"> -->
                    </div>

                  </div>
                  <div class="task__popup_wrap">
                    <div class="task__popup__list__element">
                      <div class="dur_finish">
                        <div  class="dur_finish_day">
                          <input class="dur_text" v-model="form.start_date" type="text" placeholder="Начало">
                          <div
                            class="newheader__left__bottom__calendar_third newheader__left__bottom__calendar__padding_third newheader__container">
                            <div
                              class="newheader__left__bottom__calendar_top_third newheader__left__bottom__calendar_top__padding_third">
                              <div class="newheader__left__month__second">
                                <span> {{ current_month_second }}</span>
                              </div>
                              <div class="newheader__left__flex_second">
                                <img @click="previous_month_second" class="newheader__left__arrow_third"
                                  src="@/assets/img/left_arrow_second.svg" alt="left_arrow" />
                                <img @click="next_month_second" class="newheader__left__arrow_third"
                                  src="@/assets/img/right_arrow_second.svg" alt="right_arrow" />
                              </div>
                            </div>
                            <div class="newheader__left__calendar_second">
                              <div class="newheader__left__day__names_second">П</div>
                              <div class="newheader__left__day__names_second">В</div>
                              <div class="newheader__left__day__names_second">С</div>
                              <div class="newheader__left__day__names_second">Ч</div>
                              <div class="newheader__left__day__names_second">П</div>
                              <div class="newheader__left__day__names_second">С</div>
                              <div class="newheader__left__day__names_second">В</div>
                              <div v-for="(item_second, index) in current_days_second" :key="item_second" 
                                  :class="{'bg-blue text-white': index === clickedIndex, 'bg-default text-default': index !== clickedIndex}" 
                                  @click="handleClick(item_second, index)">
                                <span>{{ item_second }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="dur">
                          <div>
                            <input class="dur_text" type="text" placeholder="Время начала" readonly>
                          </div>
                          <div class="time">
                            <div class="flex">
                              <select id="hours" class="clock" v-model="selectedHour">
                                <option v-for="hour in hours" :key="hour" :value="hour">{{
                                  hour }}</option>
                              </select>
                              <label class="label_time" for="hours">часов</label>
                            </div>
                            <div class="flex">
                              <select id="minutes" class="clock" v-model="selectedMinute">
                                <option v-for="minute in minutes" :key="minute" :value="minute">
                                  {{ minute}}</option>
                              </select>
                              <label for="minutes" class="label_time">минут(ы)</label>
                            </div>
                          </div>
                        </div>
                      </div>  
                      <div class="dur_finish">
                        <div class="dur_finish_day">
                          <input class="dur_text" v-model="form.end_date" type="text" placeholder="Завершение">
                          <div
                            class="newheader__left__bottom__calendar_second newheader__left__bottom__calendar__padding_second newheader__container">
                            <div
                              class="newheader__left__bottom__calendar_top_second newheader__left__bottom__calendar_top__padding_second">
                              <div class="newheader__left__month__second">
                                <span> {{ current_month_third }}</span>
                              </div>
                              <div class="newheader__left__flex_second">
                                <img @click="previous_month_third" class="newheader__left__arrow_second"
                                  src="@/assets/img/left_arrow_second.svg" alt="left_arrow" />
                                <img @click="next_month_third" class="newheader__left__arrow_second"
                                  src="@/assets/img/right_arrow_second.svg" alt="right_arrow" />
                              </div>
                            </div>
                            <div class="newheader__left__calendar_second">
                              <div class="newheader__left__day__names_third">П</div>
                              <div class="newheader__left__day__names_third">В</div>
                              <div class="newheader__left__day__names_third">С</div>
                              <div class="newheader__left__day__names_third">Ч</div>
                              <div class="newheader__left__day__names_third">П</div>
                              <div class="newheader__left__day__names_third">С</div>
                              <div class="newheader__left__day__names_third">В</div>
                              <div v-for="(item_third, index) in current_days_third" :key="item_third" 
                                  :class="{'bg-blue text-white': index === clickedIndexThird, 'bg-default text-default': index !== clickedIndexThird}" 
                                  @click="handleClickThird(item_third, index)">
                                <span>{{ item_third }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="dur">
                          <div>
                            <input class="dur_text" type="text" placeholder="Время завершения" readonly>
                          </div>
                          <div class="time">
                            <div class="flex">
                              <select id="hours" class="clock" v-model="selectedHour_third">
                                <option v-for="hour_third in hours_third" :key="hour_third" :value="hour_third">{{
                                  hour_third }}</option>
                              </select>
                              <label class="label_time" for="hours">часов</label>
                            </div>
                            <div class="flex">
                              <select id="minutes" class="clock" v-model="selectedMinute_third">
                                <option v-for="minute_third in minutes_third" :key="minute_third" :value="minute_third">
                                  {{ minute_third }}</option>
                              </select>
                              <label for="minutes" class="label_time">минут(ы)</label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                
              </div>
              <div class="task__popup__list__check">
                    <p class="task__popup__name_input">Список подзадач</p>
                    <div class="task__popup__list__content_big">
                      <input
                        v-model="temp_value"
                        class="task__popup__list task__popup__list__block_task"
                        name="task_emp"
                        placeholder="Введите название подзадачи"
                        @keyup.enter="add_list"
                        
                      >
                      <img @click="add_list" class="task__popup__list__image"
                        src="@/assets/img/m-assign-task/icon_task_plus.svg" alt="icon_task_plus">
                    </div>  
                    <div v-for="(item,index) in form.text" :key="index" class="task__popup__list__content" >
                      <img class="task__popup__tick" 
                      v-if="form.is_selected[index] == 1"
                      @click="toggleTick(index)" 
                      src="@/assets/img/m-assign-task/tick_task.svg" 
                      alt="tick_task">
                      <img
                      v-else
                      class="task__popup__tick__image"
                      @click="toggleTick(index)"
                      src="@/assets/img/m-assign-task/white_for_check.avif"
                      alt="empty_tick"
                          />
                      <span  class="task__popup__list_check task__popup__list__padding ">{{ item }}</span>
                      <span class="task__popup__list__remove" @click="remove_add_list(index)">✕</span>
                    </div>
                  </div> 
            </div>
            <!-- Комментарии -->
            <form class="task__popup__form">
              <p class="text__margin-top text__margin-bottom phone-comment task__popup__name_input">Описание задачи
              </p>
              <textarea 
                class="task__popup__input task__popup__text" 
                :class="{ 'input-error-in-create-task': formInputWithoutDescriptionValueError }"
                :placeholder="!formInputWithoutDescriptionValueError ? 'Введите описание' : 'Вы не ввели описание задачи'"
                type="text" 
                v-model="form.description">
              </textarea>
            </form>
            <div class="task__popup__footer">
              <button class="task__popup__file-btn task__popup__description__text">
                Подгруженный файл
                <img src="@/assets/img//m-tasks-user/file-icon.svg" class="file__icon-popup" />
              </button>
              <button @click="handleButtonClick" type="submit"
                class="task__popup__confirm-btn task__popup__description__text">Поставить задачу</button>
            </div>
          </div>
          <!-- ЗАВЕРШЕНИЕ -->
        </div>
      </section>

       <!-- попап просмотра задачи -->

       <section class="task__popup" v-show="name_task" @click="name_task = false">
        <div class="task__popup__content_user" @click.stop @click="loadTasks">
          <div class="task__popup__make">
            <h3 class="text-black"> {{ selectedTask.name }}</h3>
            <img @click="name_task = false" src="../assets/img/m-tasks-user/close-popup-icon.svg"
            class="close__icon-popup" />
          </div>
          <div class="task__popup__make task__popup__make__padding">
            <p>Срок сдачи: {{selectedTask.end_date}}</p>
          </div>
          <div class="task__popup__scroll">
            <form class="task__popup__form">
              <p class="task__popup__text text__margin-top text__margin-bottom phone-comment">
            {{ selectedTask.description }}</p>
            </form>
            <div class="task__popup__footer">
              <button class="task__popup__file-btn_user task__popup__description__text">
                Название файла
                <img src="@/assets/img//m-tasks-user/file-icon.svg" class="file__icon-popup" />
              </button>
              <div class="two_button">
                <button type="button" class="task__popup__confirm-btn_user background_blue task__popup__description__text"
                  @click="edit_task = true , name_task=false">Изменить</button>
                <button type="button" class="task__popup__confirm-btn_user task__popup__description__text"
                  @click="reopen_task = true , name_task=false">
                  <span>Приступить</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Попап выполнения задачи -->

      <section class="task__popup" v-show="reopen_task" @click="reopen_task = false">
          <div class="task__popup__content" @click.stop @click="loadTasks">
            <div class="task__popup__header">
              <h4 class="task__popup__header__status">{{ selectedTask.is_urgently ? 'Срочная задача' : 'Обычная задача'
                }}</h4>
              <img @click="reopen_task = false" src="../assets/img/m-tasks-user/close-popup-icon.svg"
                class="close__icon-popup" />
            </div>

            <div class="task__popup__scroll">
              <!-- ДЕТАЛИ ОПИСАНИЯ -->
              <h4 class="task__popup__title text__margin-bottom">
                <span class="task__popup__description__text-white">
                  {{ selectedTask.name }}
                </span>
              </h4>
              <div class="text_left">
              <p class="task__popup__description__text">Автор задачи:
                <span class="task__popup__description__text-white">
                  {{
                    concreteTaskAuthor ? (concreteTaskAuthor === 'admin') ? 'Директор' : concreteTaskAuthor : 'неуказан'
                  }}
                </span>
              </p>
              <p class="task__popup__description__text">Исполнитель:
                <span class="task__popup__description__text-white">
                  {{
                    concreteTaskAuthor !== "admin"
                        ? concreteTaskAuthor
                      : concreteTaskParticipants
                  }}
                </span>
              </p>
              <p class="task__popup__description__text">Дата:
                <span class="task__popup__description__text-white">{{ selectedTask.start_date }} - {{
                  selectedTask.end_date }}</span>
              </p>
              <p class="task__popup__description__text">Приоритетность:
                <span class="task__popup__description__text-white">
                  {{ selectedTask.is_urgently ? 'срочная задача' : 'обычная задача' }}
                </span>
              </p>
              </div>
              <p class="task__popup__text text__margin">
                <span class="task__popup__description__text-white">{{ selectedTask.description }}</span>
              </p>

              <!-- Подзадачи -->
              <ul class="task__popup__list-container">
                <li class="task__popup__list" v-for="(subtask, index) in concreteTaskChecklist" :key="index">
                  <img :src="subtask.is_selected ?
                    require('@/assets/img/m-tasks-user/list-icon.svg') :
                    require('@/assets/img/m-assign-task/white_for_check.avif')" class="list__icon-popup"
                    @click="toggleSubtaskTick(index)" alt="tick_task" />
                  <p class="task__popup__list__text">{{ subtask.text }}</p>
                </li>
              </ul>

              <!-- Комментарии -->
              <form class="task__popup__form">
                <p class="task__popup__text text__margin-top text__margin-bottom phone-comment">Комментарии к задаче</p>

                <!-- Вывод комментариев -->
                <div v-for="comment in concreteTaskComments" :key="comment" class="task__popup__comments">
                  <p class="task__popup__text" :style="{ 'user-select': 'none', 'pointer-events': 'none' }">
                    <span class="task__popup__description__text-white">{{ getUserNameByIdForComment(comment.author)
                      }}</span>
                  </p>
                  <textarea 
                  class="task__popup__input task__popup__text" 
                  :placeholder="comment.text !== ' ' ? comment.text : comment.fileName" 
                  readonly 
                  :style="{
                      'user-select': 'none',
                      'pointer-events': 'auto',
                      'overflow-y': 'auto',
                      'resize': 'none',
                  }" />
                </div>

                <!-- Добавление комментария -->
                <textarea class="task__popup__input task__popup__text"
                  placeholder="Чтобы добавить комментарий нажмите 'Enter'" type="text" v-model="newComment"
                  @keydown.enter.prevent="handleSubmit" />
              </form>

              <input 
                type="file" 
                ref="fileInput" 
                style="display: none;" 
                @change="handleFileUpload"
              />

              <button 
                @click="triggerFileInput" 
                type="button" 
                class="task__popup__file-btn task__popup__description__text"
              > 
                {{ fileName }}
                <img src="@/assets/img//m-tasks-user/file-icon.svg" class="file__icon-popup" />
              </button>
            </div>

            <!-- ЗАВЕРШЕНИЕ -->
            <button class="task__popup__confirm-btn task__popup__description__text" @click="to_completed(), reopen_task = false">Завершить
              задачу
            </button>
          </div>
        </section>

      <!-- попап изменения задачи -->

      <section class="task__popup" v-show="edit_task" @click="edit_task = false">
        <div class="task__popup__content" @click.stop>
          <div class="task__popup__header">
            <input type="text" class="task__popup__header__status" v-model="form.is_urgently" placeholder="Статус задачи"
              @mouseenter="showDropdownMenu" @blur="hideDropdown" readonly>
            <img @click="edit_task = false" src="../assets/img/m-tasks-user/close-popup-icon.svg"
              class="close__icon-popup" />
            <div class="dropdown-content__block">
              <div class="dropdown-content" @mouseenter="clearHideDropdown" v-show="showDropdown"
                @mouseleave="hideDropdown">
                <div class="dropdown_status">
                  <span>Статус задачи</span>
                </div>
                <div class="dropdown-item" @mousedown="selectItem('Обычная задача')"><span>Обычная задача</span></div>
                <div class="dropdown-item" @mousedown="selectItem('Срочная задача')"><span>Срочная задача</span></div>
              </div>
            </div>
          </div>

          <div class="task__popup__scroll">
            <!-- ДЕТАЛИ ОПИСАНИЯ -->

            <!-- ПУНКТЫ -->
            <div class="task__popup__list-container_task">
              <div class="task__popup__block">
                
                <div class="task__popup__list__content_big">
                  <div class="task__popup__list__check">
                    <p class="task__popup__name_input">Название задачи</p>
                  <input class="task__popup__list task__popup__list__block" v-model="form.name" name="task_name"
                    placeholder="Введите название задачи">
                  </div>
                </div>

                <p class="task__popup__name_input">Сроки выполнения задачи</p>
                <div class="two_calendar">
                  <div class="task__popup__list__content_time">
                    <div class="task__popup__list__content__item">
                      <input v-model="input_date" class="task__popup__list task__popup__list__block" type="text"
                        name="task_emp" placeholder="Выберите даты и время завершения задачи">
                      <!-- <img class="task__popup__list__image" src="@/assets/img/m-assign-task/icon_task_calendar.svg"
                        alt="icon_task_calendar"> -->
                    </div>

                  </div>
                  <div class="task__popup_wrap">
                    <div class="task__popup__list__element">
                      <div class="dur_finish">
                        <div  class="dur_finish_day">
                          <input class="dur_text" v-model="form.start_date" type="text" placeholder="Начало">
                          <div
                            class="newheader__left__bottom__calendar_third newheader__left__bottom__calendar__padding_third newheader__container">
                            <div
                              class="newheader__left__bottom__calendar_top_third newheader__left__bottom__calendar_top__padding_third">
                              <div class="newheader__left__month__second">
                                <span> {{ current_month_second }} {{ currentYearSecond }}</span>
                              </div>
                              <div class="newheader__left__flex_second">
                                <img @click="previous_month_second" class="newheader__left__arrow_third"
                                  src="@/assets/img/left_arrow_second.svg" alt="left_arrow" />
                                <img @click="next_month_second" class="newheader__left__arrow_third"
                                  src="@/assets/img/right_arrow_second.svg" alt="right_arrow" />
                              </div>
                            </div>
                            <div class="newheader__left__calendar_second">
                              <div class="newheader__left__day__names_second">П</div>
                              <div class="newheader__left__day__names_second">В</div>
                              <div class="newheader__left__day__names_second">С</div>
                              <div class="newheader__left__day__names_second">Ч</div>
                              <div class="newheader__left__day__names_second">П</div>
                              <div class="newheader__left__day__names_second">С</div>
                              <div class="newheader__left__day__names_second">В</div>
                              <div v-for="(item_second, index) in current_days_second" :key="item_second" 
                                  :class="{'bg-blue text-white': index === clickedIndex, 'bg-default text-default': index !== clickedIndex}" 
                                  @click="handleClick(item_second, index)">
                                <span>{{ item_second }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="dur">
                          <div>
                            <input class="dur_text" type="text" placeholder="Время начала" readonly>
                          </div>
                          <div class="time">
                            <div class="flex">
                              <select id="hours" class="clock" v-model="selectedHour">
                                <option v-for="hour in hours" :key="hour" :value="hour">{{
                                  hour }}</option>
                              </select>
                              <label class="label_time" for="hours">часов</label>
                            </div>
                            <div class="flex">
                              <select id="minutes" class="clock" v-model="selectedMinute">
                                <option v-for="minute in minutes" :key="minute" :value="minute">
                                  {{ minute}}</option>
                              </select>
                              <label for="minutes" class="label_time">минут(ы)</label>
                            </div>
                          </div>
                        </div>
                      </div>  
                      <div class="dur_finish">
                        <div class="dur_finish_day">
                          <input class="dur_text" v-model="form.end_date" type="text" placeholder="Завершение">
                          <div
                            class="newheader__left__bottom__calendar_second newheader__left__bottom__calendar__padding_second newheader__container">
                            <div
                              class="newheader__left__bottom__calendar_top_second newheader__left__bottom__calendar_top__padding_second">
                              <div class="newheader__left__month__second">
                                <span> {{ current_month_third }}  {{ currentYearThird }}</span>
                              </div>
                              <div class="newheader__left__flex_second">
                                <img @click="previous_month_third" class="newheader__left__arrow_second"
                                  src="@/assets/img/left_arrow_second.svg" alt="left_arrow" />
                                <img @click="next_month_third" class="newheader__left__arrow_second"
                                  src="@/assets/img/right_arrow_second.svg" alt="right_arrow" />
                              </div>
                            </div>
                            <div class="newheader__left__calendar_second">
                              <div class="newheader__left__day__names_third">П</div>
                              <div class="newheader__left__day__names_third">В</div>
                              <div class="newheader__left__day__names_third">С</div>
                              <div class="newheader__left__day__names_third">Ч</div>
                              <div class="newheader__left__day__names_third">П</div>
                              <div class="newheader__left__day__names_third">С</div>
                              <div class="newheader__left__day__names_third">В</div>
                              <div v-for="(item_third, index) in current_days_third" :key="item_third" 
                                  :class="{'bg-blue text-white': index === clickedIndexThird, 'bg-default text-default': index !== clickedIndexThird}" 
                                  @click="handleClickThird(item_third, index)">
                                <span>{{ item_third }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="dur">
                          <div>
                            <input class="dur_text" type="text" placeholder="Время завершения" readonly>
                          </div>
                          <div class="time">
                            <div class="flex">
                              <select id="hours" class="clock" v-model="selectedHour_third">
                                <option v-for="hour_third in hours_third" :key="hour_third" :value="hour_third">{{
                                  hour_third }}</option>
                              </select>
                              <label class="label_time" for="hours">часов</label>
                            </div>
                            <div class="flex">
                              <select id="minutes" class="clock" v-model="selectedMinute_third">
                                <option v-for="minute_third in minutes_third" :key="minute_third" :value="minute_third">
                                  {{ minute_third }}</option>
                              </select>
                              <label for="minutes" class="label_time">минут(ы)</label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                
              </div>
              <div class="task__popup__list__check">
                    <p class="task__popup__name_input">Список подзадач</p>
                    <div class="task__popup__list__content_big">
                      <input
                        v-model="temp_value"
                        class="task__popup__list task__popup__list__block_task"
                        name="task_emp"
                        placeholder="Введите название подзадачи"
                        @keyup.enter="add_list"
                      >
                      <img @click="add_list" class="task__popup__list__image"
                        src="@/assets/img/m-assign-task/icon_task_plus.svg" alt="icon_task_plus">
                    </div>  
                    <div v-for="(item,index) in form.text" :key="index" class="task__popup__list__content" >
                      <img class="task__popup__tick" 
                      v-if="form.is_selected[index] == 1"
                      @click="toggleTick(index)" 
                      src="@/assets/img/m-assign-task/tick_task.svg" 
                      alt="tick_task">
                      <img
                      v-else
                      class="task__popup__tick__image"
                      @click="toggleTick(index)"
                      src="@/assets/img/m-assign-task/white_for_check.avif"
                      alt="empty_tick"
                          />
                      <span  class="task__popup__list_check task__popup__list__padding ">{{ item }}</span>
                      <span class="task__popup__list__remove" @click="remove_add_list(index)">✕</span>
                    </div>
                  </div> 
            </div>
            <!-- Комментарии -->
            <form class="task__popup__form">
              <p class="text__margin-top text__margin-bottom phone-comment task__popup__name_input"> Описание задачи
              </p>
              <textarea class="task__popup__input task__popup__text" placeholder="Введите описание" type="text" v-model="form.description"></textarea>
            </form>
            <div class="task__popup__footer">
              <button class="task__popup__file-btn task__popup__description__text">
                Подгруженный файл
                <img src="@/assets/img//m-tasks-user/file-icon.svg" class="file__icon-popup" />
              </button>
              <button @click="submit_form" type="submit"
                class="task__popup__confirm-btn task__popup__description__text">Сохранить изменения</button>
            </div>
          </div>
          <!-- ЗАВЕРШЕНИЕ -->
        </div>
      </section>

    </div>
  </div>
</template>

<script setup>
import FooterLeft from './footer-left.vue';
import MBlockLeftLogoTitle from './block__left_logo-title.vue';
import { ref, computed, onMounted, reactive,watch } from "vue";
import { useStore } from "vuex";
// import apiClient from "../axios/axios.js";
import { useRouter } from "vue-router";

import VKAllowMessages from './m-VKAllowMessages.vue';
const toggleNotifications = ref(false);

const store = useStore();
const router = useRouter();
const random_token = ref("");
const query = ref("");
const token = computed(() => store.getters.getToken);
// const searchResults = computed(() => store.getters.getSearch || []);

const formInputWithoutStatusValueError = ref(false);
const formInputWithoutNameValueError = ref(false);
const formInputWithoutDateValueError = ref(false);
const formInputWithoutDescriptionValueError = ref(false);

const logout = async () => {
  if (token) {
    store.dispatch("log_Out");
    router.push({ name: "MLogin" });
  } else {
    console.log(error);
  }
};

const currentYear = ref(new Date().getFullYear());
const currentMonth = ref(new Date().getMonth() + 1); // 1 - январь, 12 - декабрь

const getDaysInMonth = (year, month) => {
  const daysInMonth = new Date(year, month, 0).getDate(); // Количество дней в месяце
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay(); // День недели первого числа (0 - воскресенье, 1 - понедельник и т.д.)
  const shift = (firstDayOfMonth === 0) ? 6 : firstDayOfMonth - 1;
  
  const calendarDays = Array(shift).fill(null); // Заполняем пустыми значениями до первого дня месяца
  for (let day = 1; day <= daysInMonth; day++) {
    calendarDays.push(day); // Добавляем дни месяца
  }
  return calendarDays;
};

const calendarDays = computed(() => getDaysInMonth(currentYear.value, currentMonth.value));

// Метод для перехода на следующий месяц
const next_month = () => {
  if (currentMonth.value === 12) { // Декабрь
    currentMonth.value = 1; // Январь
    currentYear.value += 1; // Увеличиваем год
  } else {
    currentMonth.value += 1; // Увеличиваем месяц
  }
  updateWeek(1);
};

// Метод для перехода на предыдущий месяц
const previous_month = () => {
  if (currentMonth.value === 1) { // Январь
    currentMonth.value = 12; // Декабрь
    currentYear.value -= 1; // Уменьшаем год
  } else {
    currentMonth.value -= 1; // Уменьшаем месяц
  }
  updateWeek(1);
};

const today = new Date();
const todayDays = today.getDate();
const current_year = ref(today.getFullYear()); // год установленный
const currentDayIndex = today.getDay(); // 0 - воскресенье, 1 - понедельник и т.д.
const weekDates = ref([]);
const year = current_year.value; // Текущий год

  // Получаем день недели  и месяц

  const daysOfWeek = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота']; 
const monthsOfYear = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
const dayOfWeek = daysOfWeek[today.getDay()]; // Получаем день недели


const currentMonthName = computed(() => monthsOfYear[currentMonth.value - 1]);

// Форматируем дату в виде ДД.ММ.ГГГГ

const month = String(today.getMonth() + 1).padStart(2, '0'); // ММ (месяцы начинаются с 0)
const day = today.getDate(); // Текущий день
// const formattedDate = `${year}-${month}-${day}`;

// Форматируем дату в виде ДД месяца ГГГГ
const monthsOfYearText = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
  'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
const month_name_text = monthsOfYearText[today.getMonth()]; // Название месяца
const formatted_month_Date = `${day} ${month_name_text} ${year}`;

// Функция для обновления дней недели
const updateWeek = (day) => {
  const selectedDate = new Date(currentYear.value, currentMonth.value - 1, day);
  const startOfWeek = new Date(selectedDate);
  startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() || 7) + 1); // Пн
  weekDates.value = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i);
    weekDates.value.push(date.getDate()); // Добавляем только число
  }
  days.value = []
  filteredTaskCheck.value = []
  preparedTasks.value = []
  renderedTaskIds.value.clear()
  getWeekDays(day)
  preparedTasks.value = prepareTasksForWeek();
  selectedDay.value = `${currentYear.value}-${String(currentMonth.value).padStart(2, '0')}-${day}`;
  selectedDateWeek.value = formatDate(selectedDay.value);
};


// Инициализация массива при монтировании компонента
onMounted(() => {
  updateWeek(todayDays); // Инициализируем массив при монтировании компонента
});

// попап

const open_task = ref(false);
const name_task = ref(false);
const reopen_task = ref(false);
const edit_task = ref(false);
const assign_task_button= ref(false);

//Работа с задачами
const cards = computed(() => {
const taskUser = store.getters.getTaskUser || [];
  //console.log("Task User from Vuex:", taskUser);
  return taskUser;
    });

const form = reactive ({
  name: "",
  description: "",
  start_date: "",
  end_date: "",
  is_urgently: "",
  participant: [],
  participants: [], // Ключ для помощи в формировании запроса по исполнителям задачи
  text: [], // Для Списка подзадач (текст подзадачи)
  is_selected: [], // Для списка подзадач (галочка)
});
const formRequestKeys = reactive({
  name: 'name=',
  description: 'description=',
  start_date: 'start_date=',
  end_date: 'end_date=',
  is_urgently: 'is_urgently=',
  participant: '',
  text: '', // Для Списка подзадач (текст подзадачи)
  is_selected: '', // Для списка подзадач (галочка)
});

// Работа с типом задачи
const showDropdown = ref(false);
let hideDropdownTimeout = null;
const selectItem = (value) => {
  form.is_urgently = value;
  formInputWithoutStatusValueError.value = false;
  showDropdown.value = false;
};

/* Наблюдение заполнено ли имя задачи */
watch(() => form.name, (taskName) => {
  if (taskName) formInputWithoutNameValueError.value = false;
  else formInputWithoutNameValueError.value = true;
});

/* Наблюдение заполнено ли описание задачи */
watch(() => form.description, (taskDescription) => {
  if (taskDescription) formInputWithoutDescriptionValueError.value = false;
  else formInputWithoutDescriptionValueError.value = true;
});


const showDropdownMenu = () =>{
  
  setTimeout(() =>{
    showDropdown.value = true;
  }, 400)
}
const hideDropdown = () => {
      hideDropdownTimeout = setTimeout(() => {
        showDropdown.value = false;
      }, 20);
    }
    const clearHideDropdown = () => {
      clearTimeout(hideDropdownTimeout);
    };
// работа с ответственными
// const selectedParticipiants = computed(() => store.getters.getSelectParticipiant || []);

const filteredCards = computed(() => {
  return cards.value.filter(card => 
    card.name.toLowerCase().includes(currentParticipant.value.toLowerCase())
  );
});

// Функция для добавления участника самого себя для юзера

/* Берем данные из формы и отправляем для Создания задачи */
const submit_form = async () => {
  
  if (!form.is_urgently) {
    formInputWithoutStatusValueError.value = true;
  }
  if (!form.name) {
    formInputWithoutNameValueError.value = true;
  }
  if (!form.start_date || !form.end_date) {
    formInputWithoutDateValueError.value = true;
  }
  if (!form.description) {
    formInputWithoutDescriptionValueError.value = true;
  }
  if (formInputWithoutStatusValueError.value || 
      formInputWithoutNameValueError.value ||
      formInputWithoutDateValueError.value ||
      formInputWithoutDescriptionValueError.value
      ) return;

  if (form.is_urgently === "Обычная задача") form.is_urgently = 0;
  else if (form.is_urgently === "Срочная задача") form.is_urgently = 1;
  formRequestKeys.participant = store.state.user_id
  console.log(formRequestKeys.participant);
  
  formRequestKeys.text = form.text.map(subtask => `text[]=${subtask}`).join("&");
  formRequestKeys.is_selected = form.is_selected.map(is_selected => `is_selected[]=${is_selected*1}`).join("&");
  for (const key in form) {
    if (key === 'participant') {
      formRequestKeys.name += `:::${store.state.user_name}`;
      break;
    }
    formRequestKeys[key] += form[key];
  }
  const formDataForCreateTaskInText = Object.values(formRequestKeys).join("&");
  form.is_urgently = '';
  formRequestKeys.name = 'name=';
  formRequestKeys.description = 'description=';
  formRequestKeys.start_date = 'start_date=';
  formRequestKeys.end_date = 'end_date=';
  formRequestKeys.is_urgently = 'is_urgently=';
  formRequestKeys.participant = '';
  formRequestKeys.text = '';
  formRequestKeys.is_selected = '';
  open_task.value = false;
  await store.dispatch('createTask', { form: formDataForCreateTaskInText, file: selectedFile.value });
  loadTasks()
};

/// Кнопка создания и отрисовки задачи

const handleButtonClick = async() => {
  submit_form(); 
  if (!formInputWithoutStatusValueError.value &&
      !formInputWithoutNameValueError.value &&
      !formInputWithoutDateValueError.value &&
      !formInputWithoutDescriptionValueError.value
      ) {
    assign_task_button.value = true;
    open_task.value = false;
  } 
  const { startOfWeek, endOfWeek } = getWeekRange(new Date(days.value));
  await loadTasks(startOfWeek, endOfWeek);
  window.location.reload()
};

/// Календарь second

const currentYearSecond = ref(new Date().getFullYear());
const currentMonthSecond = ref(new Date().getMonth() + 1);
const current_month_second = computed(() => monthsOfYear[currentMonthSecond.value - 1]);
const current_days_second = computed(() => getDaysInMonth(currentYearSecond.value, currentMonthSecond.value));

const next_month_second = () => {
  if (currentMonthSecond.value === 12) { // Декабрь
    currentMonthSecond.value = 1; // Январь
    currentYearSecond.value += 1; // Увеличиваем год
  } else {
    currentMonthSecond.value += 1; // Увеличиваем месяц
  }
};

const previous_month_second = () => {
  if (currentMonthSecond.value === 1) { // Январь
    currentMonthSecond.value = 12; // Декабрь
    currentYearSecond.value -= 1; // Уменьшаем год
  } else {
    currentMonthSecond.value -= 1; // Уменьшаем месяц
  }
};


const today_second = new Date();
const todayDays_second = today_second.getDate();
const hours = computed(() => Array.from({ length: 13 }, (_, i) => i + 8));
const minutes = computed(() => Array.from({ length: 60 }, (_, i) => i < 10 ?  + i : i));
const selectedHour = ref(new Date().getHours());
const selectedMinute = ref(new Date().getMinutes());
const selected_day = ref(todayDays_second);

// выделение срока на календаре
const isClicked = ref(false);
const toggleClick = () => {
    isClicked.value = !isClicked.value; // Переключаем состояние
};

let clickedIndex = ref(null)

const select_date = (day) => {
  selected_day.value = day;
  update_Date();
};

const update_Date = () => {
  const year = currentYearSecond.value;
  const month = currentMonthSecond.value;
  const day = selected_day.value;
  const hour = selectedHour.value;
  const minute = selectedMinute.value;
  form.start_date = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
};

function handleClick(item, index) {
  clickedIndex.value = index
  toggleClick(); // Call toggleClick
  select_date(item); // Call select_date with the clicked item
};

watch([selectedHour, selectedMinute], update_Date);
// const open_task_second = ref(false);

// Календарь third

const currentYearThird = ref(new Date().getFullYear());
const currentMonthThird = ref(new Date().getMonth() + 1);
const today_third = new Date();
// const todayMonth_third = today_third.getMonth() + 1;
const todayDays_third = today_third.getDate();
const current_month_third = computed(() => monthsOfYear[currentMonthThird.value - 1]);
const current_days_third = computed(() => getDaysInMonth(currentYearThird.value, currentMonthThird.value));
const hours_third =  computed(() => Array.from({ length: 13 }, (_, i) => i + 8));
const minutes_third = computed(() => Array.from({ length: 60 }, (_, i) => i < 10 ?  + i : i));
const selectedHour_third = ref(new Date().getHours());
const selectedMinute_third = ref(new Date().getMinutes());
const selected_day_third = ref(todayDays_third);



const next_month_third = () => {
  if (currentMonthThird.value === 12) { // Декабрь
    currentMonthThird.value = 1; // Январь
    currentYearThird.value += 1; // Увеличиваем год
  } else {
    currentMonthThird.value += 1; // Увеличиваем месяц
  }
};

const previous_month_third = () => {
  if (currentMonthThird.value === 1) { // Январь
    currentMonthThird.value = 12; // Декабрь
    currentYearThird.value -= 1; // Уменьшаем год
  } else {
    currentMonthThird.value -= 1; // Уменьшаем месяц
  }
};

const clickedIndexThird = ref(null);

const select_date_third = (day_third) => {
  selected_day_third.value = day_third;
  update_Date_third();
};

const update_Date_third = () => {
  const year_third = currentYearThird.value;
  const month_third = currentMonthThird.value;
  const day_third = selected_day_third.value;
  const hour_third = selectedHour_third.value;
  const minute_third = selectedMinute_third.value;
  form.end_date = `${year_third}-${month_third < 10 ? '0' + month_third : month_third}-${day_third < 10 ? '0' + day_third : day_third} ${hour_third < 10 ? '0' + hour_third : hour_third}:${minute_third < 10 ? '0' + minute_third : minute_third}`;
  if (form.end_date < form.start_date) {
    form.end_date = 'Завершение должно быть позже начала';
  }
};

function handleClickThird(item, index) {
  clickedIndexThird.value = index
  toggleClick(); // Call toggleClick
  select_date_third(item); // Call select_date with the clicked item
};

watch([selectedHour_third, selectedMinute_third], () => {
  update_Date_third();
});

let input_date = computed(() => {
  if (form.start_date || form.end_date) {
    if (form.end_date === 'Завершение должно быть позже начала')
      formInputWithoutDateValueError.value = true;
    else
      formInputWithoutDateValueError.value = false;
    return `${form.start_date}  —  ${form.end_date}`;
  }
  return '';
});

// const open_task_third = ref(false);

//Чек-лист
const temp_value = ref("");
const add_list = () => {
  if (temp_value.value.trim() !== "") {
    form.text.push(temp_value.value); // Добавляем введенное значение в массив
    form.is_selected.push(false); // Добавляем 0 в массив is_selected, чтобы галочка была не выбрана
    temp_value.value = ""; // Очищаем временную переменную
  }
}
const remove_add_list = (index) =>{
  form.text.splice(index,true);
  form.is_selected.splice(index, true); // Удаляем соответствующее значение из is_selected
}

 // tick
 const toggleTick = (index) => {
  form.is_selected[index] = form.is_selected[index] === false ? true : false;
}

// function addChecklistItem() {
//   form.text.push({ name: `Task ${form.text.length + 1}` });
//   form.is_selected.push(false); // По умолчанию добавляем выбранную галочку
// }

// ЭТО ДЛЯ ОТКРЫТИЯ бургера - календаря на мобилке/планшете
const mobile_calendar = ref(true);

onMounted(() => {
  let windowInnerWidth = window.innerWidth

  if (windowInnerWidth > 1024) {
    mobile_calendar.value = true
  } else {
    mobile_calendar.value = false
  }

  window.addEventListener('resize', () => {
    let windowInnerWidth = window.innerWidth

    if (windowInnerWidth > 1024) {
      mobile_calendar.value = true
    } else {
      mobile_calendar.value = false
    }
  })
});

const handleDateClick = (item) => {
  mobile_calendar.value = false; // Закрыть попап
  updateWeek(item); // Обновить неделю
};
// ЭТО ДЛЯ ОТКРЫТИЯ календаря на мобилке/планшете КОНЕЦ

//ОТКРЫТИЕ ФИЛЬТРОВ
const menu_popup = ref(false);
//ОТКРЫТИЕ ПОИСКА
const search_open = ref(false);

/* Загрузка файла при Создании задачи */
const fileInput = ref(null);
const fileName = ref('Подгруженный файл');
const selectedFile = ref(null);
const triggerFileInput = () => {
  fileInput.value.click();
};
const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    fileName.value = file.name;
    selectedFile.value = file;
  }
};


//ОТОБРАЖЕНИЕ ЗАДАЧ В КАЛЕНДАРИКЕ НА ГЛАВНОМ ЭКРАНЕ
const parseTimeToMinutes = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};
// Отображение задачи динамически на экране

const tasks = computed(() => store.getters.getShowUserTasks);

const TimeIntervals = [
  { start: "08:00", end: "11:00" },
  { start: "11:00", end: "14:00" },
  { start: "14:00", end: "17:00" },
  { start: "17:00", end: "20:00" }
];

// Преобразование строки даты в объект Date с учетом локального времени
const parseDate = (dateString) => {
  const [datePart, timePart] = dateString.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
};

const days = ref([])


const getWeekDays = (selectedDate) => {
  const date = new Date(currentYear.value, currentMonth.value - 1, selectedDate);
  
  // Находим первый день недели (Понедельник)
  const dayOfWeek = date.getDay(); // Номер дня недели (0 — Воскресенье, 1 — Понедельник и т.д.)
  const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Корректируем, чтобы всегда начиналось с Понедельника
  const startOfWeek = new Date(date.setDate(date.getDate() + diffToMonday));
  

  for (let i = 0; i < 7; i++) {
    const nextDay = new Date(startOfWeek);
    nextDay.setDate(startOfWeek.getDate() + i);
    days.value.push(new Date(currentYear.value, currentMonth.value - 1, nextDay.getDate()));
  }
  return days;
};


const getTaskDaysRange = (task) => {
  const taskStart = new Date(task.start_date);
  const taskEnd = new Date(task.end_date);

  const startOfWeek = days.value[0];
  const endOfWeek = days.value[6];

  const startDay = taskStart < startOfWeek ? startOfWeek : taskStart;
  const endDay = taskEnd > endOfWeek ? endOfWeek : taskEnd;


  return {
    startDay,
    endDay,
    daysNumber: (endDay - startDay) / (1000 * 60 * 60 * 24) + 1
  };
};




// Получение задач для дня и интервала времени
const renderedTaskIds = ref(new Set());

const getTasksForDayAndInterval = (dayIndex, interval) => {
  const weekDays = days.value;
  if (!weekDays[dayIndex - 1]) {
    console.error(`Day not found for index: ${dayIndex}`);
    return [];
  }

  const dayStart = new Date(weekDays[dayIndex - 1]);
  const dayEnd = new Date(dayStart);
  dayEnd.setDate(dayStart.getDate() + 1);

  const filteredTasks = tasks.value.filter(task => {
    const taskStart = parseDate(task.start_date);
    const taskEnd = parseDate(task.end_date);

    if (isNaN(taskStart) || isNaN(taskEnd)) {
      console.error("Invalid task dates:", task);
      return false;
    }

    const isTaskInDay = taskStart < dayEnd && taskEnd >= dayStart;
    const isInInterval = isTaskEndingInInterval(task, interval);

    return isTaskInDay && isInInterval;
  });

  return filteredTasks;
};


const isTaskEndingInInterval = (task, interval) => {
  if (!task || !task.end_date || !interval) return false;

  const taskEndDate = parseDate(task.end_date);
  const taskEndMinutes = taskEndDate.getHours() * 60 + taskEndDate.getMinutes();

  const intervalStartMinutes = parseTimeToMinutes(interval.start);
  const intervalEndMinutes = parseTimeToMinutes(interval.end);

  // Отображаем задачу только в одном интервале
  return taskEndMinutes >= intervalStartMinutes && taskEndMinutes <= intervalEndMinutes;
};

const filteredTaskCheck = ref([])

const preparedTasks = ref({});

// Предварительная фильтрация задач по дням
const prepareTasksForWeek = () => {
  const tasksForWeek = {};
  for (let dayIndex = 0; dayIndex < days.value.length; dayIndex++) {
    const currentDay = days.value[dayIndex];
    tasksForWeek[dayIndex] = {};
    TimeIntervals.forEach((interval) => {
      tasksForWeek[dayIndex][interval.start] = tasksForCell(dayIndex + 1, interval);
    });
  }
  return tasksForWeek;
};


// Получение задач, которые начинаются в данный день
const tasksForCell = (dayIndex, interval) => {
  // Получаем задачи для текущего дня и интервала
  const tasksInInterval = getTasksForDayAndInterval(dayIndex, interval);
  
  // Фильтруем задачи, исключая те, которые уже были отрисованы
  const filteredTasks = tasksInInterval.filter((task) => {
    // Проверка на уже отрисованные задачи
    if (renderedTaskIds.value.has(task.id)) { 
      return false;
    }
    
    // Проверка: задача должна отображаться в текущий день
    const taskStart = parseDate(task.start_date);
    const taskEnd = parseDate(task.end_date);
      
    const currentDay = days.value[dayIndex]; 
    
    const shouldDisplay = taskStart <= currentDay && taskEnd >= currentDay;
    // console.log(`Задача: ${task.id}, taskStart: ${taskStart}, taskEnd: ${taskEnd}, currentDay: ${currentDay}, shouldDisplay: ${shouldDisplay}`);

    
    
    if (shouldDisplay) {
      // Добавляем задачу в Set, чтобы не отрисовывать её снова в этом дне
      renderedTaskIds.value.add(task.id);
      return true;
    }

    return false;
  });
  filteredTaskCheck.value.push(filteredTasks)
  return filteredTasks;
};




// Функция загрузки задач
const loadTasks = async (startOfWeek, endOfWeek) => {
  await store.dispatch('showAllUserTasks', { isCompleted: false });
  tasks.value = store.getters.getShowUserTasks.filter(task => {
    const taskStart = new Date(task.start_date);
    const taskEnd = new Date(task.end_date);

    return (taskStart <= endOfWeek && taskEnd >= startOfWeek);
  });
  renderedTaskIds.value.clear()
};


// Функция для расчета диапазона текущей недели
const getWeekRange = (selectedDate) => {
  const startOfWeek = new Date(selectedDate);
  startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() || 7) + 1); // Пн
  
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6); // Вс

  return { startOfWeek, endOfWeek };
};

const intervalTaskCounts = ref({});
const taskOrdinalMap = ref({}); // Object to track ordinal numbers

const calculateIntervalTaskCounts = () => {
  const counts = {};
  const intervalOrdinalMaps = {}; // Map for ordinals within each interval
  
  // Initialize intervalOrdinalMaps for each interval
  TimeIntervals.forEach(interval => {
    intervalOrdinalMaps[interval.start] = new Map();
  });

  // Calculate unique task counts and ordinals for each interval
  TimeIntervals.forEach(interval => {
    const uniqueTasksForInterval = new Set();
    let ordinalNumber = 0; // Ordinals reset for each interval
    
    for (let i = 0; i < days.value.length; i++) {
      const tasksInInterval = getTasksForDayAndInterval(i + 1, interval);

      tasksInInterval.forEach(task => {
        const taskId = task.id;
        if (!uniqueTasksForInterval.has(taskId)) {
          uniqueTasksForInterval.add(taskId);
          intervalOrdinalMaps[interval.start].set(taskId, ordinalNumber++);
        }
      });
    }
    
    // Count unique tasks for this interval
    counts[interval.start] = uniqueTasksForInterval.size;
    
  });

  intervalTaskCounts.value = counts;
  taskOrdinalMap.value = intervalOrdinalMaps; // Set the interval-specific ordinal maps
  

};


// Генерация стилей для задачи на основе времени
const getTaskStyles = (task, taskIndex, dayIndex, intervalStart) => {
  const { startDay, endDay, daysNumber } = getTaskDaysRange(task);
  const pixelsPerDay = 90;

  const startOfWeek = new Date(days.value[0]);
  const endOfWeek = new Date(days.value[6]);


  const taskWidth = endDay >= endOfWeek
    ? ((endOfWeek - startDay) / (1000 * 60 * 60 * 24) + 1) * pixelsPerDay + 200
    : daysNumber * pixelsPerDay;

  const ordinalMap = taskOrdinalMap.value[intervalStart] || new Map();
  const taskOrdinal = ordinalMap.get(task.id);
  let overlapOffset = 0
if(taskOrdinal < 1)
{
  overlapOffset = 0
} else{
  overlapOffset = taskOrdinal * 40;
}

  return {
    width: `${taskWidth / 10.5}rem`,
    top: `${overlapOffset}px`,
    borderRadius: '10px',
    zIndex: dayIndex + 1,
    border: 'none',
    backgroundColor: getRandomColorsForCalendarTasks(task.is_urgently).backgroundColor,
    color: getRandomColorsForCalendarTasks(task.is_urgently).textColor
  };
};


// Загружаем задачи при монтировании компонента
onMounted(async()=>{
  const { startOfWeek, endOfWeek } = getWeekRange(new Date(days.value));
  await loadTasks(startOfWeek, endOfWeek);
  calculateIntervalTaskCounts();
    // Подготовка задач для недели после их загрузки
    preparedTasks.value = prepareTasksForWeek();
});

//Функция открытия задачи в календаре(попап) 
const selectedTask = ref({});


const openTaskDetails = (task) => {
  selectedTask.value = task;
  selectedTask.value.name = selectedTask.value.name.split(':::')[0];
  selectedTask.value.start_date = selectedTask.value.start_date.slice(0, 16).replace(/-/g, '.');
  selectedTask.value.end_date = selectedTask.value.end_date.slice(0, 16).replace(/-/g, '.');
  name_task.value = true;
  store.dispatch("showConcreteTaskExtraData", task.id);
};

const concreteTaskAuthor = computed(() => store.getters.getConcreteTaskAuthor);
const concreteTaskParticipants = computed(() => store.getters.getConcreteTaskParticipants);
const concreteTaskChecklist = computed(() => store.getters.getConcreteTaskChecklist);
const concreteTaskComments = computed(() => store.getters.getConcreteTaskComments);
/* Подгружаем список всех пользователей для отображения имен в комментариях к задачам */
const allUsers = computed(() => store.getters.getTaskUser);

/* Получение имени сотрудника (автора комментария к задаче) */
const getUserNameByIdForComment = (userId) => {
  if (userId === 1) return 'Директор';
  const user = allUsers.value.find(user => user.id === userId);
  return user ? user.name : `Сотрудник № ${userId}`;
};

//Переключение чекбоксов в попапе задачи
const toggleSubtaskTick = (index) => {
  const checklist = concreteTaskChecklist.value;
  checklist[index].is_selected = !checklist[index].is_selected;
  store.commit('set_Concrete_task_checklist', checklist);
  store.dispatch('updateConcreteTaskChecklistStatus', checklist[index]);
};

//Отправка коментариев
const newComment = ref('');
const handleSubmit = async () => {
  await store.dispatch('submitComment', {
    taskId: selectedTask.value.id,
    commentText: newComment.value,
    userId: selectedTask.value.creator_id
  });
  newComment.value = '';
};

//Завершение задачи
const to_completed = async () => {
  const taskId = selectedTask.value.id; // Получите ID задачи из выбранной задачи
  const url = `http://51.21.19.52:8000/api/task/update/${taskId}?is_completed=1`;

  try {
    const response = await apiClient.patch(url, {
      token: token.value, // Используйте токен из Vuex
    });

    if (response.status === 200) {
      console.log('Задача успешно завершена:', response.data);
      name_task.value = false; // Закрыть попап
      // Здесь можно обновить список задач или состояние, если это нужно
      await store.dispatch("showAllAdminTasks", { isCompleted: false, link: "" });
      loadTasks() // Обновить список задач
    } else {
      console.error('Ошибка при завершении задачи:', response.data);
    }
  } catch (error) {
    console.error('Ошибка:', error.response ? error.response.data : error.message);
  }
}

/* Случайный выбор цвета задач в календаре */
const urgentRedColors = ['#FF7C7C', '#ffaaaa', '#FFD4D4'];
const normalBlueColors = ['#80B9EF', '#b5d5ff', '#5CA5EA'];  // убрала '#056bf0'
const getBrightness = (hexColor) => {
  const color = hexColor.replace('#', '');
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  return (r * 299 + g * 587 + b * 114) / 1000;
};
const getTextColor = (backgroundColor) => {
  // return getBrightness(backgroundColor) > 128 ? '#000000' : '#FFFFFF';
  return getBrightness(backgroundColor) > 128 ? '#000000' : '#000000';
};
const getRandomColorsForCalendarTasks = (isUrgent) => {
  const colorsForCalendarTasks = isUrgent ? urgentRedColors : normalBlueColors;
  const selectedColor = colorsForCalendarTasks[Math.floor(Math.random() * colorsForCalendarTasks.length)];
  const textColor = getTextColor(selectedColor);
  return { backgroundColor: selectedColor, textColor: textColor };
};

// Функция перевода даты  2024-09-08  в 8 сентября 2024 года
function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', options);
}

// вывод задач в планшете
const dayToday = `${day}.${month}.${year}`;
const formattedToday = `${year}-${month}-${day}`;
const selectedDay = ref(formattedToday); // По умолчанию сегодня
const selectedDateWeek = ref(formatted_month_Date); // По умолчанию текущая дата в формате "ДД месяц ГГГГ"

const updateSelectedDate = (dayIndex) => {
  selectedDay.value = `${currentYear.value}-${String(currentMonth.value).padStart(2, '0')}-${String(weekDates.value[dayIndex]).padStart(2, '0')}`;
  selectedDateWeek.value = formatDate(selectedDay.value);
};
const todayTasks = computed(() => {
  return tasks.value.filter(task => {
    const taskStartDate = task.start_date.split(' ')[0]; // Получаем только дату без времени
    return taskStartDate === selectedDay.value; // Сравниваем с сегодняшней датой
  });
});

</script>
 
<style scoped>

.dimention {
  width: 410px;
  top: 500px;
  left: 528px;
}
  
  
  .task_assign {
    position: absolute;
    height: 61px;
    margin-top: 8px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    @media (max-width: 1024px) {
      display: none;
    }  
  }  
  .color.probl {
      background-color: red;
      border-radius: 5px; 
      margin: 5px; 
  }
  /* задачи */
  .dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content__block{
  z-index: 10;
    position: absolute;
    right: 85%;
    display: flex;
}
.dropdown-content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  text-align: start;
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 8px;
  z-index: 1;
  width: 200px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.dropdown_status {
  margin-bottom: 8px;
}
.dropdown-content .dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content .dropdown-item:hover {
  background-color: #d1e7ff;
  /* font-size: 16px; */
}

.dropdown.show .dropdown-content {
  display: block;
}

.dropdown_partip {
  position: relative;
  display: inline-block;
}
.dropdown-content__block_partip{
  z-index: 10;
    position: absolute;
    right: 75%;
    top: 180px;
    display: flex;
}
.dropdown-content_partip {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  text-align: start;
  padding-left: 23px;
  padding-top: 20px;
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 8px;
  z-index: 1;
  width: 300px;
}
.dropdown-content_title{
  padding-left: 0px;
}
.dropdown-content_partip .dropdown-item_partip {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content_partip .dropdown-item_partip:hover {
  background-color: #d1e7ff;
}

.dropdown_partip.show_partip .dropdown-content_partip {
  display: block;
}
.participant {
  font-size: 16px;
  font-weight: 600;
}
.button-close {
  background: none;
  border: none;
  padding-left: 8px;
}
  /* стили для открытия бургера */
  
  .close-img {
    display: none;
  }
  
  @media (max-width: 1024px) {
    .newheader {
      flex: 1;
      margin: 0px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .newheader_right {
      width: 100vw; 
    }
    .newheader__right__empl1 {
      display: none;
    }
    .newheader__left {
      position: fixed;
      background-image: url('../assets/img/m-tasks/mobile-calendar-bg.svg');
      background-size: cover;
      z-index: 999;
      top: 0;
      left: 0;
      width: 100vw;
      max-width: 100vw;
      height: 100vh;
      max-height: 100vh;
      overflow: scroll;
    }
    .close-img {
      display: block;
    }
    .newheader__left__bottom {
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      background: none;
      margin: 0px !important;
      padding-right: 0px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10vh;
    }
    .newheader__left__bottom__calendar {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
    }
    .newheader__left__bottom__track {
      margin-left: 0px;
      margin-right: 0px;
    }  
    .newheader__left__text {
      display: none;
    }
    .newheader__left__token {
      display: flex;
      margin-left: 0px;
      margin-right: 0px;
    }
    .newheader__left__token__gen {
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      border-radius: 12px;
      background-color: rgb(235, 242, 255);
      border: 1px solid transparent;
      width: 100%;
      max-width: 290px;
      font-weight: 600;
      font-size: 16px;
    }
    .newheader__left__token__block {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 11.7px;
      width: 100%;
      max-width: 290px;
    }
    
    .newheader__left__token__block input {
    width: 290px;
    max-width: 100%;
    border-radius: 12px;
    height: 71px;
    border: 0 transparent;
    font-size: 14px;
    text-align: center;
    }
    .newheader__left__token__padding {
      padding-top: 27px;
      margin-left: 0px;
    }
  }


@media (max-width: 375px) {
  .newheader__left__token__padding {
    padding-top: 20px;
  }
}  
  
.bg-blue {
    background-color: blue; /* Синий фон */
    border-radius: 40px;
}

.text-white {
    color: white; /* Белый текст */
}

.bg-default {
    background-color: transparent; /* Прозрачный фон или другой цвет по умолчанию */
}

.text-default {
    color: black; /* Цвет текста по умолчанию */
}

.input-error-in-create-task {
  border-color: red;
}
.input-error-in-create-task::placeholder {
  color: red;
}

.task-cell {
  min-width: 100px;
  border: 1px solid #ddd;
  vertical-align: top;
  position: relative;
}

.task-container {
  position: absolute;
  height: 40px; 
  border-radius: 4px;
  padding: 5px;
  box-sizing: border-box;
}

.task-block {
  display: block;
  width: 100%;
  padding: 6px;
  overflow: hidden;
  text-align: center;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
}

@media (max-width: 1024px) {
  .task-block {
    padding: 12px;
    border-radius: 20px;
  }
}
  </style>

.bodiTable {

}